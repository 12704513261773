<template>
    <div class="solutions">
        <div class="title">Solutions</div>

        <div class="grid">
            <div class="box">
                <div class="image">
                    <img src="../../assets/community.svg">
                </div>
                <div class="label">Community Engagement</div>
                <div class="text">
                    Empowers local communities to actively participate in waste management efforts through 
                    incentivization, fostering a sense of ownership and responsibility.
                </div>
            </div>
            <div class="box">
                <div class="image">
                    <img src="../../assets/cost.svg">
                </div>
                <div class="label">Cost Efficiency</div>
                <div class="text">
                    Reduces overall waste management costs by optimizing collection routes, 
                    maximizing recycling efforts, and minimizing operational inefficiencies.
                </div>
            </div>
            <div class="box">
                <div class="image">
                    <img src="../../assets/environmental.svg">
                </div>
                <div class="label">Environmental Sustainability</div>
                <div class="text">
                    Promotes eco-friendly practices by prioritizing recycling, reducing landfill waste, 
                    and minimizing carbon footprints associated with traditional waste management methods.
                </div>
            </div>
            <div class="box">
                <div class="image">
                    <img src="../../assets/blockchain.svg">
                </div>
                <div class="label">Transparency and Accountability</div>
                <div class="text">
                    Utilizes blockchain technology to ensure transparent transactions, traceability of 
                    waste disposal processes, and secure reward distributions to participants.
                </div>
            </div>
            <div class="box">
                <div class="image">
                    <img src="../../assets/gift.svg">
                </div>
                <div class="label">Customer Incentives</div>
                <div class="text">
                    Rewards individuals and communities for their contributions to waste reduction and recycling efforts, 
                    encouraging sustained participation and behavior change.
                </div>
            </div>
            <div class="box">
                <div class="image">
                    <img src="../../assets/service.svg">
                </div>
                <div class="label">Scalability and Adaptability</div>
                <div class="text">
                    Adapts to varying community needs and waste management challenges, scaling operations 
                    efficiently while maintaining high standards of service.
                </div>
            </div>
            <div class="box">
                <div class="image">
                    <img src="../../assets/data.svg">
                </div>
                <div class="label">Data-Driven Insights</div>
                <div class="text">
                    Utilizes data analytics to optimize waste collection schedules, identify trends, 
                    and implement proactive measures for continuous improvement.
                </div>
            </div>
            <div class="box">
                <div class="image">
                    <img src="../../assets/law.svg">
                </div>
                <div class="label">Regulatory Compliance</div>
                <div class="text">
                    Helps businesses and municipalities adhere to environmental regulations and sustainability 
                    goals through compliant waste management practices.
                </div>
            </div>
            <div class="box">
                <div class="image">
                    <img src="../../assets/cleaning.svg">
                </div>
                <div class="label">Corporate Social Responsibility (CSR)</div>
                <div class="text">
                    Enhances corporate image and social responsibility profiles by supporting sustainable waste 
                    management practices and community involvement initiatives.
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>

    .solutions {
        background-color: white;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        margin-top: -30px;
        padding: 20px;
        padding-top: 50px;
        padding-bottom: 100px;
        position: relative;
        z-index: 2;
    }
    .title {
        text-align: center;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 50px;
    }

    .grid {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

        margin: 0 auto;
        max-width: 1100px;
        gap: 30px;
    }
    .box {
        padding: 30px;
        padding-top: 20px;
        border-radius: 20px;
        border: 2px solid var(--color-1);

        border-top-color: transparent;
        border-left-color: transparent;

        /* overflow: hidden; */

        position: relative;
    }
    .box::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;

        width: 40px;
        height: 40px;
        /* border-radius: 10px; */
        border-top-left-radius: 18px;

        
        border: 2px solid var(--color-1);

        border-bottom-color: transparent;
        border-right-color: transparent;

        /* background-color: var(--color-1); */
    }
    .image {
        width: 80px;
        aspect-ratio: 1/1;
        /* border-radius: 50%; */

        /* border: 2px solid transparent; */
        border-left-color: rgb(0, 196, 0);
        border-top-color: rgb(0, 196, 0);

        border-top-left-radius: 20px;

        display: flex;
        align-items: center;
        justify-content: center;

        /* margin: 0 auto; */
        margin-bottom: 20px;

        /* background-color: #f4f4f4; */

        /* transform: rotate(45deg); */
    }
    .image img {
        width: 100%;
        /* transform: rotate(-45deg); */
    }
    .label {
        font-size: 20px;
        font-weight: 800;
        /* text-align: center; */
    }
    .text {
        font-size: 14px;
        line-height: 23px;
        margin-top: 20px;
    }
</style>