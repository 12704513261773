<template>
    <div class="home">
        <support_vue/>
        <header_vue @show="scroll_to_func($event)"/>
        <div ref="banner">
            <banner_vue @show="scroll_to_func($event)"/>
        </div>
        <div ref="about">
            <about_vue/>
        </div>
        <div ref="problems">
            <problems_vue/>
        </div>
        <div ref="solutions">
            <solutions_vue/>
        </div>
        <div ref="signup">
            <signup_vue/>
        </div>
        <footer_vue/>
    </div>
</template>

<script setup>
import support_vue from '@/components/home/support.vue'
import header_vue from "@/components/header.vue";
import banner_vue from "@/components/home/banner.vue";
import about_vue from "@/components/home/about.vue";
import problems_vue from "@/components/home/problems.vue";
import solutions_vue from "@/components/home/solutions.vue";
import signup_vue from "@/components/home/signup.vue";
import footer_vue from "@/components/home/footer.vue";
import { ref } from "vue";


const banner = ref(null)
const about = ref(null)
const problems = ref(null)
const solutions = ref(null)

const signup = ref(null)

function scroll_to_func(el) {
    if (el == 'banner') {
        banner.value.scrollIntoView({ behavior: "smooth" })
    }
    if (el == 'about') {
        about.value.scrollIntoView({ behavior: "smooth" })
    }
    if (el == 'problems') {
        problems.value.scrollIntoView({ behavior: "smooth" })
    }
    if (el == 'solutions') {
        solutions.value.scrollIntoView({ behavior: "smooth" })
    }
    if (el == 'signup') {
        signup.value.scrollIntoView({ behavior: "smooth" })
    }
}

</script>


<style scoped>
</style>