<template>
    <div class="about">
        <div class="title">About</div>
        <div class="flex">
            <div class="container">
                <div class="content_container">
                    <div class="image">
                        <img src="../../assets/walomoo logo.png"/>
                    </div>
                    <div class="content">
                        <div class="label">Description</div>
                        <div class="text">
                            Walomoo is a pioneering decentralized waste management solution that revolutionizes the industry 
                            through customer incentivization and operational optimization. Our mission is to transform waste 
                            and litter management into a seamless, eco-friendly process that benefits communities and the 
                            environment alike.
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="content_container">
                    <div class="image">
                        <img src="../../assets/how.svg"/>
                    </div>
                    <div class="content">
                        <div class="label">How it works</div>
                        <div class="text">
                            At Walomoo, we combine cutting-edge technology with a customer-centric approach to efficiently 
                            manage waste and litter. Through decentralized operations, we empower local communities to actively 
                            participate in waste reduction efforts while earning rewards for their contributions. By leveraging 
                            blockchain technology, our platform ensures transparency, security, and accountability throughout 
                            the waste management cycle.
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="content_container">
                    <div class="image">
                        <img src="../../assets/service.svg"/>
                    </div>
                    <div class="content">
                        <div class="label">Service</div>
                        <div class="text">
                            Our comprehensive suite of services includes waste collection, recycling initiatives, and 
                            innovative disposal methods that minimize environmental impact. We prioritize sustainability 
                            by optimizing resource utilization and reducing carbon footprints across all operations.
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="content_container">
                    <div class="image">
                        <img src="../../assets/check.svg"/>
                    </div>
                    <div class="content">
                        <div class="label">Commitment</div>
                        <div class="text">
                            Walomoo is committed to setting new standards in waste management by promoting cleaner, healthier 
                            environments and fostering community engagement. Join us in creating a greener future, one 
                            sustainable initiative at a time.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
    .about {
        background-color: white;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        margin-top: -30px;
        padding: 20px;
        padding-top: 50px;
        padding-bottom: 100px;
    }
    .title {
        text-align: center;
        font-size: 35px;
        font-weight: 700;
    }

    .flex {
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        /* grid-template-columns: 60% 40%; */
        /* align-items: center; */
        justify-content: center;
        gap: 50px;
    }
    .container {
        padding: 0.1px;
    }
    .content_container {
        /* display: flex; */
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 196, 0, 0.3);
        height: 100%;
        padding: 30px;
        border-radius: 20px;
        max-width: 600px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 50px;

        position: relative;
    }
    .content_container::after {
        content: "";
        position: absolute;
        z-index: 2;
        bottom: 0px;
        right: 0px;

        width: 50px;
        height: 50px;

        border-bottom-right-radius: 20px;
        border: 2px solid transparent;
        border-bottom-color: #00C400;
        border-right-color: #00C400;
    }
    .content_container::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;

        width: 50px;
        height: 50px;

        border-top-left-radius: 20px;
        border: 2px solid transparent;
        border-top-color: #00C400;
        border-left-color: #00C400;
    }
    .image {
        /* max-width: 600px; */
        /* width: 100%; */
        margin: 0 auto;
        margin-top: -70px;
        margin-bottom: 20px;
        background-color: white;
        width: fit-content;

        /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
        border: 2px solid transparent;
        border-top-color: var(--color-1);

        display: flex;
        align-items: center;
        justify-content: center;

        aspect-ratio: 1/1;
        border-radius: 50%;
        padding: 10px;
    }
    .image img {
        width: 100px;
    }
    .content {
    }
    .label {
        font-size: 25px;
        font-weight: 800;
    }
    .text {
        margin-top: 20px;
        line-height: 25px;
    }


</style>