<template>
    <div class="footer_container">
        <div class="footer">
            <div class="logo">
                <img src="../../assets/walomoo-text.png"/>
            </div>
            <div class="social">
                <div class="svg">
                    <img src="../../assets/fb.svg"/>
                </div>
                <div class="svg">
                    <img src="../../assets/x.svg"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
    .footer_container {

        background-color: white;

        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        padding: 30px;
        box-sizing: border-box;
        
        margin-top: -30px;

    }

    .logo img {
        width: 100px;
        display: block;
    }

    .social {
        display: flex;
        align-items: center;
        gap: 25px;
    }
    .svg img {
        width: 20px;
        display: block;
    }
</style>