<template>
    <router-view/>
</template>

<script setup>

</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

:root {
    --color-1: #00C400;
    --color-2: #007500;
    --color-3: #FF8000;
}
#app {
    font-family: "Overpass", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;

}
body {
    padding: 0px;
    margin: 0px;
    scrollbar-width: thin;
}


a {
    color: inherit;
    text-decoration: none;
    width: fit-content;
}
a:active, a:focus {
    background-color: unset;
    color: unset;
    -webkit-tap-highlight-color: transparent;
}

textarea, input, select {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
}

</style>
