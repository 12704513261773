<template>
    <div class="banner">
        <div class="info_container">
            <div class="info">
                <div>
                    <!-- <div class="logo">
                        <img src="../../assets/walomoo-text.png"/>
                    </div> -->
                    <!-- <div class="title">WALOMOO</div> -->
                    <div class="text">Waste And Litters Organised, Managed, Optimized Operations</div>
                </div>
                <div class="how">
                    <div>
                        <div class="label">We Sort, Scale, and Organize</div>
                        <div class="value">
                            Ensuring efficient and effective waste management.
                        </div>
                    </div>
                    <div>
                        <div class="label">Reward Disposers</div>
                        <div class="value">
                            Earn rewards for responsible waste disposal.
                        </div>
                    </div>
                    <div>
                        <div class="label">Transfer Waste to Manufacturers</div>
                        <div class="value">
                            Facilitating recycling and resource recovery for a sustainable future.
                        </div>
                    </div>
                </div>
                <div class="btn" @click="emit('show', 'signup')">Waitlist Signup</div>
            </div>
        </div>
        <div class="images">
            <div class="image-box svg">
                <img src="../../assets/waste-mgt.svg"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(['show'])

</script>

<style scoped>
    .banner {
        display: grid;
        align-items: center;
        grid-template-columns: 60% 40%;
        justify-content: space-around;
        min-height: calc(100vh - 53px);
        padding: 20px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;

        overflow: hidden;

        background-color: #f4f4f4;

        position: relative;
    }


    .info_container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .info {
        display: flex;
        flex-direction: column;
        gap: 30px;
        /* max-width: 50%; */
    }
    
    .title {
        font-family: "Ubuntu Sans", sans-serif;
        font-size: 50px;
        font-weight: bolder;
        color: var(--color-1);
    }
    .text {
        font-family: "Ubuntu Sans", sans-serif;
        /* margin-top: 10px; */
        /* font-size: 14px; */
        font-size: 40px;
        font-weight: 800;
        max-width: 600px;
        color: var(--color-2);
    }

    .how {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .label {
        font-weight: 700;
        font-size: 18px;
    }
    .value {
        font-size: 14px;
    }
    
    .btn {
        padding: 15px 25px;
        border-radius: 7px;
        background-color: var(--color-1);
        font-weight: 500;
        color: white;
        cursor: pointer;
        width: fit-content;
    }

    .images {
        position: relative;
    }

    .image-box {
        padding: 20px;
        aspect-ratio: 1/1;


        display: flex;
        align-items: center;
        justify-content: center;

        /* box-shadow: 0px 0px 10px #ddd; */
        border-radius: 50px;
    }
    .svg {
        /* background-color: rgba(255, 255, 255, 1); */
        /* width: 80%; */
        position: relative;
        z-index: 2;
        border: 2px solid transparent;
        /* border-right-color: var(--color-1); */
        /* border-bottom-color: var(--color-1); */
        /* opacity: .7; */
    }
    .logo {
        position: absolute;
        top: 0%;
        right: 0px;
        display: none;
    }

    .image-box img {
        width: 100%;
    }

    @media screen and (max-width: 1000px) {
        .banner {
            padding-top: 100px;
            padding-bottom: 100px;
            grid-template-columns: 100%;
        }
        
        .image-box {
            aspect-ratio: unset;
        }
        .image-box img {
            width: 70%;
        }
    }
    
    @media screen and (max-width:750px) {
        .banner {
            padding-top: 40px;
            gap: 50px;
        }
        .image-box img {
            width: 80%;
        }
    }


</style>