<template>
    <div class="signup">
        <div class="info_container">
            <div class="info">
                <div class="tag">Waitlist Signup</div>
                <div class="title">Join the Waitlist</div>
                <div class="text">
                    Be among the first to be notified when Walomoo goes live
                </div>
            </div>
        </div>
        <div class="input">
            <div class="label">Enter your email</div>
            <input type="email" placeholder="Email address">
            <div class="btn">Submit</div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>

    .signup {
        display: flex;
        align-items: center;
        gap: 40px;
        flex-wrap: wrap;
        /* grid-template-columns: 50% 50%; */
        /* align-items: center; */
        justify-content: space-around;
        
        padding: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
        box-sizing: border-box;
        
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        /* border-top: 2px solid var(--color-1); */

        background-color: #f4f4f4;
        background-color: #2F4858;
        background: linear-gradient(to top left, #2F4858, var(--color-1));
        color: white;


    }
    .info_container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        width: 100%;
    }
    .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* max-width: 50%; */
    }
    .tag {
        /* background-color: white; */
        /* padding: 10px; */
        font-weight: 600;
        color: #fff;
        width: fit-content;
        font-size: 14px;
        text-decoration: underline;
        text-underline-offset: 5px;
    }
    .title {
        font-family: "Ubuntu Sans", sans-serif;
        font-size: 50px;
        font-weight: bolder;
        /* color: var(--color-1); */
    }
    .text {
        font-size: 14px;
    }

    .input {

        max-width: 400px;
        width: 100%;
    }
    .label {
        font-size: 18px;
    }
    input {
        padding: 15px;
        border-radius: 7px;
        border: 1px solid var(--color-1);
        border: none;
        width: 100%;
        box-sizing: border-box;

        /* max-width: 400px; */
        margin: 20px 0px;
    }
    .btn {
        text-align: center;
        padding: 12px 25px;
        border-radius: 7px;
        background-color: var(--color-1);
        font-weight: 500;
        color: white;
        cursor: pointer;
        /* max-width: 400px; */
        box-sizing: border-box;
    }

    @media screen and (max-width:650px) {
        .signup {
            
            padding-top: 50px;
            padding-bottom: 70px;
        }
        .title {
            font-size: 35px;
        }
    }
</style>