<template>
    <div class="problems">
        <div class="title">Problems</div>
        <div class="flex-container">
            <div class="flex">
                <div class="problem">
                    <div class="label">Inefficient Waste Collection</div>
                    <div class="text">
                        Traditional waste management systems often suffer from inefficiencies in collection routes and schedules, 
                        leading to increased operational costs and environmental impact due to unnecessary fuel consumption and 
                        emissions. Walomoo optimizes waste collection through decentralized operations and data-driven analytics, 
                        improving efficiency and reducing costs.
                    </div>
                </div>
                <div class="problem">
                    <div class="label">Lack of Community Engagement</div>
                    <div class="text">
                        Many communities lack active participation in waste reduction efforts, leading to increased littering and 
                        improper disposal practices. Walomoo incentivizes individuals and communities to engage in recycling and 
                        proper waste management practices by offering rewards, thereby fostering a sense of responsibility and 
                        ownership.
                    </div>
                </div>
                <div class="problem">
                    <div class="label">Environmental Pollution</div>
                    <div class="text">
                        Improper waste disposal contributes to environmental pollution, including land and water contamination, 
                        greenhouse gas emissions from landfill sites, and harm to wildlife. Walomoo promotes recycling and 
                        responsible waste disposal methods, reducing environmental pollution and promoting sustainability.
                    </div>
                </div>
                <div class="problem">
                    <div class="label">Limited Recycling Rates</div>
                    <div class="text">
                        Despite growing awareness, recycling rates remain relatively low in many areas due to logistical challenges 
                        and lack of motivation. Walomoo enhances recycling rates by incentivizing participants to recycle through 
                        rewards, making recycling more attractive and accessible.
                    </div>
                </div>
                <div class="problem">
                    <div class="label">Transparency and Accountability Issues</div>
                    <div class="text">
                        Current waste management practices often lack transparency and accountability in waste disposal processes, 
                        leading to concerns about illegal dumping and mismanagement. Walomoo utilizes blockchain technology to 
                        ensure transparency in transactions and traceability in waste disposal, enhancing accountability and trust.
                    </div>
                </div>
                <div class="problem">
                    <div class="label">High Operational Costs</div>
                    <div class="text">
                        Traditional waste management systems can be costly to operate, especially in densely populated areas with 
                        complex waste management needs. Walomoo reduces operational costs through optimized routes, efficient 
                        resource allocation, and streamlined processes, making waste management more sustainable and cost-effective.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>

    .problems {
        background-color: var(--color-1);
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        margin-top: -30px;
        padding: 20px;
        padding-top: 50px;
        padding-bottom: 100px;

        background: url('../../assets/bg2.jpg');
        background-size: cover;
        background-repeat: no-repeat;

    }
    .title {
        text-align: center;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 30px;
        color: white;
    }
    .flex {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;

        /* padding: 30px; */
        /* background-color: white; */
        border-radius: 30px;
        width: fit-content;
        margin: 0 auto;
        margin-top: 50px;
    }
    .problem {
        max-width: 600px;
        width: 100%;
        padding: 20px;
        background-color: white;
        /* background-color: rgba(0, 196, 0, 0.3); */
        border-left: 4px solid var(--color-3);
    }
    .label {
        font-weight: 800;
    }
    .text {
        margin-top: 15px;
        line-height: 23px;
        font-size: 14px;
        opacity: .8;
    }
    

    @media screen and (max-width:850px) {
        .problems {
            background-position: center;
        }
    }
</style>