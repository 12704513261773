<template>
    <div class="head">
        <div class="logo">
            <img src="../assets/walomoo-text.png"/>
        </div>
        <div class="menu">
            <div class="nav" @click="emit('show', 'banner')">Home</div>
            <div class="nav" @click="emit('show', 'about')">About</div>
            <div class="nav" @click="emit('show', 'problems')">Problems</div>
            <div class="nav" @click="emit('show', 'solutions')">Solution</div>
            <div class="btn" @click="emit('show', 'signup')">Sign Up</div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(['show'])

</script>

<style scoped>
.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: sticky;
    z-index: 5;
    top: 0px;
    background-color: white;
}

.logo img {
    width: 150px;
    cursor: pointer;
}

.menu {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.nav {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.btn {
    padding: 10px 25px;
    border-radius: 7px;
    background-color: var(--color-1);
    font-weight: 500;
    color: white;
    cursor: pointer;
}

@media screen and (max-width:750px) {
    .menu {
        gap: 20px;
    }
}
@media screen and (max-width:600px) {
    .nav {
        display: none;
    }
}
</style>